import React from 'react';
import { graphql } from 'gatsby';
import BaseLayout from '../../components/templates/BaseLayout';
import SEO from '../../components/atoms/utility/seo';
import Button from '../../components/atoms/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faCommentDots } from '@fortawesome/free-solid-svg-icons';



const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

const staticSchemaMarkupData = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  name: 'flockx',
  logo: 'https://res.cloudinary.com/fetch-ai/image/upload/f_auto/q_auto/v1679063482/flockx-website/Logos/flockx-logo-google_xb9ted.png',
  image:
    'https://res.cloudinary.com/fetch-ai/image/upload/v1736515174/flockx-website/Landing%20page/hero_banner_e2dlsh.png',
  sameAs: [
    'https://twitter.com/flockxofficial',
    'https://flockx.io',
    'https://www.linkedin.com/company/flockxofficial/',
  ],
  keywords: [
    'ai agent',
    'flockx',
    'AI Agent platform',
    'Streamline AI Agent',
    'Personalized AI Agent',
    'AI Agent knowledge management',
    'Automated AI Agent updates',
    'Scalable AI solutions',
    'AI integrations',
    'Automate Q&A',
    'AI Agent engagement',
  ].toString(),
  founder: 'Humayun Sheikh',
  slogan: 'No Code AI Agent Platform',
  address: {
    '@type': 'PostalAddress',
    streetAddress: '99 Monroe',
    addressLocality: 'Grand Rapids',
    addressRegion: 'MI',
    postalCode: '49503',
    addressCountry: 'USA',
    floor: '2nd Floor',
  },
  geo: {
    '@type': 'GeoCoordinates',
    latitude: 30.405504665093982,
    longitude: -97.72119716931535,
  },
};

const SEO_KEYWORDS = [
  'flockx',
  'AI Agent platform',
  'Personalized AI Agent',
  'Scalable AI solutions',
  'AI Agent',
  'AI Agent engagement',
];


export default function HomePage({
  data: {
    site: {
      siteMetadata: { description, siteUrl, title },
    },
  },
}) {
  const schemaMarkup = {
    ...staticSchemaMarkupData,
    description,
    url: siteUrl,
  };

  const handleCTAButtonClick = () => {
    window?.gtag('event', 'primary_variant_cta_click', {
      event_category: 'CTA',
      event_label: 'Primary Variant CTA',
    });

    window.open('https://community.flockx.io/api/auth/login', '_blank');
  };


  // infographic component  
const FeatureBlock = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col gap-2 mb-8 md:items-start items-center text-center md:text-left">
    <div>
      <Icon width={128} height={128} />
    </div>
    <h3 className="text-2xl font-extrabold ">{title}</h3>
    <p className=" text-lg">{description}</p>
  </div>
);


  // Add features data
  const features = [
    {
      icon: () => (
        <img 
          src='https://res.cloudinary.com/fetch-ai/image/upload/v1736517664/flockx-website/Landing%20page/light-bulb_d2atxb.svg'
          alt="Centralized Information"

        />
      ),
      title: 'Centralized Information', 
      description: 'Eliminates scattered channels of info'
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736517662/flockx-website/Landing%20page/clock_zajldg.svg"
          alt="Save Time"

        />
      ),
      title: "Save Time",
      description: "Automate routine Q&A and tasks"
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736517661/flockx-website/Landing%20page/book-open_m5n7xb.svg"
          alt="Preserve Knowledge"

        />
      ),
      title: "Preserve Knowledge", 
      description: "Teach your AI Agent with a personal knowledge base"
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736517660/flockx-website/Landing%20page/004-high_five_you5vf.svg"
          alt="Scale Personal Attention"

        />
      ),
      title: "Scale Personal Attention",
      description: "Maintain community intimacy at scale"
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736517660/flockx-website/Landing%20page/Refresh_umcgdg.svg"
          alt="Streamlined Updates"

        />
      ),
      title: "Streamlined Updates",
      description: "Automated flow to drive AI Agent updates"
    },
    {
      icon: () => (
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736517659/flockx-website/Landing%20page/users_tpjnax.svg"
          alt="Engage Members"

        />
      ),
      title: "Engage Members",
      description: "Gamified experience for users"
    }
  ];

// Chat Card component 
const ChatCard = ({ avatar, community, comm_ai, description, href }) => (
  <div className="flex flex-col max-w-sm p-6 bg-white rounded-2xl shadow-xl">
    <div className="flex items-center justify-between mb-4">
      <div className="flex items-center">
        <img 
          src={avatar}
          alt={`${community} AI`}
          className="w-12 h-12 rounded-full mr-4"
        />
        <div>
          <h3 className="text-xl font-bold">{community}</h3>
          <p className="text-sm ">{comm_ai}</p>
        </div>
      </div>
      <a 
        href={href}
        className="inline-flex items-center px-4 py-2 bg-primary text-white rounded-full hover:bg-primary/90 transition-colors"
      >
        <FontAwesomeIcon icon={faCommentDots} className="w-4 h-4 mr-2" />
        Chat
      </a>
    </div>
    <p className=" line-clamp-2">{description}</p>
  </div>
);

const chatAIs = [
  {
    avatar: "https://res.cloudinary.com/fetch-ai/image/upload/v1736774055/flockx-website/Landing%20page/FoodieAI_a1yc9a.jpg",
    community: "Foodies",
    comm_ai: "Chef James",
    description: "🍜 Hungry for adventure? I'm James, your foodie AI guide! From hidden gems to hot spots, I've got the inside scoop on the best local eats.",
    href: "https://community.flockx.io/communities/34cde72b-cab3-48f2-9fec-39d48d0a4e33"
  },
  {
    avatar: "https://res.cloudinary.com/fetch-ai/image/upload/v1736774055/flockx-website/Landing%20page/MusicAI_mkklbt.jpg",
    community: "Live Music", 
    comm_ai: "Rocky",
    description: "🎸 Hey music lovers! I'm Rocky, your backstage AI Agent! From intimate gigs to major concerts, I'll keep you in the loop on the local music scene.",
    href: "https://community.flockx.io/communities/cd7ec04c-8c60-427e-a293-f7dffa8aadde"
  },
  {
    avatar: "https://res.cloudinary.com/fetch-ai/image/upload/v1736774055/flockx-website/Landing%20page/BasketballAI_c9nwt7.jpg",
    community: "Basket baller",
    comm_ai: "Wilt", 
    description: "🏀 What's good, ballers! Wilt here, your hoops-loving AI Agent! From pickup games to local leagues, I've got the scoop on all things basketball.",
    href: "https://community.flockx.io/communities/d318ba3d-ab17-47eb-919e-98d83ffcbc95"
  }
];



  return (
    <BaseLayout
      mainSectionClass="relative flex-grow h-full w-full"
      hideHeroImage
      customHeaderClass="fixed w-full z-50 bg-transparent"
    >
      <SEO
        title="The Future of AI Agent Knowledge"
        keywords={SEO_KEYWORDS}
        description="Turn Your Insights into Living Intelligence, Unique AI for You"
        schemaMarkup={schemaMarkup}
      />
      {/* Hero Section */}
      <div className="relative h-screen pt-12 flex items-center">
        {/* Content */}
        <div className="relative z-10 w-full">
          <div className="container mx-auto px-4 md:px-6">
            <div className="flex flex-col md:flex-row items-center justify-center gap-12">
              {/* Image content - Moved above text for mobile */}
              <div className="w-full md:w-1/2 flex justify-center md:justify-end order-first md:order-last">
                <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1736515174/flockx-website/Landing%20page/hero_banner_e2dlsh.png"
                  alt="AI Agent Platform"
                  className="w-full max-w-2xl object-cover"
                />
              </div>

              {/* Text content */}
              <div className="w-full md:w-1/2 text-center md:text-left order-last md:order-first">
                <div className="max-w-xl mx-auto md:mx-0">
                  <h1 className="text-2xl md:text-3xl lg:text-4xl 2xl:text-5xl font-bold tracking-regular mb-4 md:mb-6">
                    The Future of <br></br>AI Agents & Knowledge Bases
                  </h1>
                  <p className="text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
                    Turn Your Insights into Living Intelligence, Unique AI for you
                  </p>
                  <Button
                    label="Get Started Now"
                    onClickHandler={handleCTAButtonClick}
                    id="Get Started Now"
                    className="!bg-primary font-bold hover:!bg-primary/90 !from-primary !to-primary transition-colors !px-16"

                    isSecondaryButton={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


    {/* problem statement section  */}
    <section className="pt-24 md:pt-16 pb-4 px-4 md:px-6 lg:px-8 bg-gray-50">
    <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
    Have you wondered...
  </h2>
  <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
  Why building a thriving community feels harder than it should?
          </p>
    <div className="max-w-7xl mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-2 items-center">
      {/* Image - Shows first on mobile */}
      <div className="order-1 md:order-2">
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736767850/flockx-website/Landing%20page/thinking-puppy_w7aacg.png"
          alt="Start Building AI Agent"
          className=""
        />
      </div>
      
      {/* Text Content */}
      <div className="order-2 md:order-1">
      <div className="card-statement top flex flex-col items-center ">
        <img className="card-image-floating top" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736768599/flockx-website/Landing%20page/icon-set-01_diodnh.png"></img>
        <h2 className="text-md md:text-xl font-bold"> 
        Why am I managing information instead of building connections?
        </h2>
      </div>

      <div className="card-statement left flex flex-row items-center py-4">
        <img className="card-image-floating left w-1/4" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736768598/flockx-website/Landing%20page/icon-set-02_ehgxux.png"></img>
        <h2 className="flex-1 text-md md:text-xl font-bold "> 
        Why is our knowledge scattered across different platforms?
        </h2>
      </div>

      <div className="card-statement right flex flex-row items-center">
    
        <h2 className="flex-1 text-md md:text-xl font-bold "> 
        Why am I answering the same questions over and over?
        </h2>
        <img className="card-image-floating right w-1/4" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736940425/flockx-website/Landing%20page/icon-set-03_blqhm7.png"></img>
      </div>


      </div>
     </div>
     </div>

    </section>




      {/* Community AI Section */}
      <section className="py-32 px-4 md:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <div className="flex flex-col md:flex-row gap-24">
            {/* Left Column - 1/3 width */}
            <div className="w-full md:w-1/3">
              <div className="flex flex-col items-center md:items-start gap-4">
              <img 
                  src="https://res.cloudinary.com/fetch-ai/image/upload/v1736518045/flockx-website/Landing%20page/hey-community-ai_v1xofg.png"
                  alt="AI Agent Mascot"
                  className="w-full max-w-sm"
                />
                <div className="mb-4 text-center md:text-left">
                  <h1 className="text-3xl md:text-4xl font-bold pb-1">
                    Unlock the Power of AI for Your Community
                  </h1>
                  <p className=" text-lg">
                  Empowering creators to streamline community growth, all while saving time and scaling personal attention.
                  </p>
                  <a href="https://community.flockx.io/" className="inline-flex items-center justify-center md:justify-start w-full md:w-auto text-primary hover:text-primary/80 font-semibold mt-4">
                    Start Building your Community AI &nbsp;
                    <FontAwesomeIcon icon={faChevronRight} />
                  </a>
                </div>
              </div>
            </div>

            {/* Right Column - 2/3 width */}
            <div className="w-full md:w-2/3">
              <div className="grid md:grid-cols-2 gap-x-4 gap-y-6 ">
                {features.map((feature, index) => (
                  <FeatureBlock
                    key={index}
                    icon={feature.icon}
                    title={feature.title}
                    description={feature.description}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>


  {/* Chat AIs Section */}
  <section className="py-24 px-4 md:px-6 lg:px-8 bg-gray-50">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
            Meet Our AI Powered Communities 
          </h2>
            <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
            Explore vibrant communities guided by AIs that grow smarter with every interaction. Ready to connect, assist, and engage your members like never before.
          </p>
          
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 md:gap-2 mb-12">
          {chatAIs.map((ai, index) => (
            <ChatCard
              key={index}
              avatar={ai.avatar}
              community={ai.community}
              comm_ai={ai.comm_ai}
              description={ai.description}
              href={ai.href}
            />
          ))}
          </div>

          <div className="flex justify-center">
            <Button
              label="Create your own AI"
              onClickHandler={handleCTAButtonClick}
              id="Create AI"
              className="!bg-primary hover:!bg-primary/90 !from-primary !to-primary !px-16"
              isSecondaryButton={false}
            />
          </div>
        </div>
      </section>


  {/* building your AI section */}
  <section className="py-16 px-4 md:px-6 lg:px-8 ">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl md:text-4xl font-bold text-center pb-2">
          A Smarter Way To Build Community
          </h2>
          <p className="text-center text-base md:text-lg lg:text-xl 2xl:text-2xl font-regular mb-6 md:mb-8 leading-7 md:leading-8 lg:leading-9">
          Let your AI co-founder handle the details while you build something great  
          </p>
          
          {/* row 1 */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">

          <div className="card-base-text">
          <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">01</h1>
          <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Create Community Powered AI</h2>
          <p className="text-lg text-center md:text-left">Create unique AI that represents your community</p>
          </div>

          <div className="card-base px-6 flex flex-col items-center pt-1">
     
           
           <div className="relative flex flex-col items-center w-full">
              <div className="card-basic text-center">
             
              <div className="flex items-center pt-3 pl-4"> 
                <img className="mr-3" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736933611/flockx-website/Landing%20page/ai-icon_gyxjyf.svg"></img>
                <h3 className="!p-0 text-lg font-extrabold text-center">
                Your Own Community AI
                </h3>
              </div>
              <div className="pt-2 pl-4">
                <p className='pb-3'>
               Create your AI, and share your knowledge.
                </p>
                      <Button
                    label="Create Your Community AI"
                    onClickHandler={handleCTAButtonClick}
                    id="Create AI"
                    className="!bg-gray-100 !text-gray-700 hover:!bg-gray-200 !border-0 !px-8 !py-3 !text-sm !font-bold"
                    isSecondaryButton={true}
                    />
               </div>

              </div>
              <img className="card-hidden-image mx-auto" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736775964/flockx-website/Landing%20page/foodie_vr6byd.png" alt="Foodie AI" />
            </div>
            
          </div>  
                  

        

          <div className="card-base">
            <h3 className="text-lg font-extrabold text-center pt-4">
            Customize Your AI
            </h3>
            <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736764037/flockx-website/Landing%20page/blocks-02_zdclv0.png"></img>
            </div>
           </div>


          {/* row 2 */}
           <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
     
            <div className="card-base order-2 md:order-1">
            <h3 className="text-lg font-extrabold text-center pt-4">
            Flockx Premium Integrations with your Knowledge Base
              </h3>
              <img className="pt-4" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736936828/flockx-website/Landing%20page/blocks-03_ymkjom.png"></img>
            </div>

            <div className="card-base order-3 md:order-2">
              <h3 className="text-lg font-extrabold text-center pt-4">
              Participate in Daily Challenge
              </h3>
              <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736777627/flockx-website/Landing%20page/blocks-04_vxefhf.png"></img>
              </div>
              
              <div className="card-base-text order-1 md:order-3">
            <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">02</h1>
            <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Enrich Your<br></br>Community AI</h2>
            <p className="text-lg text-center md:text-left">Train Your AI Through Chat and Premium Integrations</p>
            </div>
            
            </div>



          {/* row 3 */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12 ">
          
          <div className="card-base-text">
          <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">03</h1>
          <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Engage with Your Community AI</h2>
          <p className="text-lg text-center md:text-left">Multiple ways to engage your thriving community</p>
          </div>

          <div className="card-base">
          <h3 className="text-lg font-extrabold text-center pt-4">
          Interactive Chat Experience
            </h3>
            <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736939387/flockx-website/Landing%20page/blocks-05_cm9yi9.png"></img>
          </div>

          <div className="card-base">
            <h3 className="text-lg font-extrabold text-center pt-4">
            Have Fun with AI in Flocks
            </h3>
            <img className="pt-8" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736777624/flockx-website/Landing%20page/blocks-06_tvi1sp.png"></img>
            </div>
                     
          </div>


          {/* row 4 */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
     
          <div className="card-base order-2 md:order-1">
          <h3 className="text-lg font-extrabold text-center py-4">
          Connect to Where Your Members Are
            </h3>
            <img className="" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736777623/flockx-website/Landing%20page/blocks-07_z8trzx.png"></img>
          </div>

          <div className="card-base order-3 md:order-2">
            <h3 className="text-lg font-extrabold text-center pt-5">
            Proactive Messages & Notification
            </h3>
            <img className="pt-3" src="https://res.cloudinary.com/fetch-ai/image/upload/v1736777623/flockx-website/Landing%20page/blocks-08_mgpwai.png"></img>
            </div>
            
            <div className="card-base-text order-1 md:order-3">
          <h1 className="text-5xl md:text-6xl font-extrabold py-2 text-gray-300 text-center md:text-left">04</h1>
          <h2 className="text-3xl md:text-4xl font-black pb-1 text-center md:text-left">Elevate Your <br></br>Community </h2>
          <p className="text-lg text-center md:text-left">Stay connected with your community wherever they are</p>
          </div>
          
          </div>

        </div>
      </section>

          
{/* Call to Action Section */}
<section className="py-8 px-4 md:px-6 lg:px-8 bg-primary">
  <div className="max-w-7xl mx-auto">
    <div className="grid grid-cols-1 md:grid-cols-3 gap-2 items-center">
      {/* Image - Shows first on mobile */}
      <div className="order-1 md:order-2 md:col-span-1">
        <img 
          src="https://res.cloudinary.com/fetch-ai/image/upload/v1736766634/flockx-website/Landing%20page/bulb-puppy_qrdivl.png"
          alt="Start Building Community AI"
          className="w-full max-w-md mx-auto"
        />
      </div>
      
      {/* Text Content */}
      <div className="order-2 md:order-1 text-white md:col-span-2 text-center md:text-left">
        <h2 className="text-4xl md:text-5xl font-bold mb-4">
          Start Building your Community AI
        </h2>
        <p className="text-lg md:text-xl mb-8">
        Bring your vision to life with an AI tailored to your community, start creating today and watch connections thrive
        </p>
        <div className="flex justify-center md:justify-start">
          <Button
            label="Create Your Community AI"
            onClickHandler={handleCTAButtonClick}
            id="Create Your Community AI"
            className="!bg-white font-bold !text-primary hover:!bg-gray-100 !from-white !to-white !px-8"
            isSecondaryButton={false}
          />
        </div>
      </div>
    </div>
  </div>
</section>




    </BaseLayout>
  );
}

export const query = graphql`
  query ActivityHomePageQuery {
    site {
      siteMetadata {
        description
        siteUrl
      }
    }
  }
`;
